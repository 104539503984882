<template>
  <div class="content-container">
    <router-view :login="login" />
  </div>
</template>

<script>
import { isMobile } from "@/utils/device"

export default {
  data () {
    return {
      login: !isMobile()
        ? {
          formType: null,
          formErrMsg: '',
          allTypes: {
            SignUp: '注册',
            SignIn: '会员登录',
            Forget: '找回密码'
          },
          isLogin: false,
          data: null
        }
        : {
          formType: null,
          formErrMsg: '',
          allTypes: {
            SignUp: '欢迎注册多彩网',
            SignIn: '欢迎登入多彩网',
            Forget: '找回密码'
          },
          isLogin: false,
          data: null
        }
    }
  }
}
</script>

<style lang="scss" scoped>
.content-container{
  min-height: inherit;
  div {
    min-height: inherit;
  }
}
</style>